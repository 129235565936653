import React, { useContext, useRef } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Button } from "../../Core";
import { navigate } from "gatsby";
import GlobalContext from "./../../../context/GlobalContext";
import CheckAvailability from "../../../components/Rebrand/CheckAvailability/CheckAvailability";

const HeroVoucher = (props) => {

    const scrollToRef = (ref) => {
        if (ref && ref.current)
            ref.current.scrollIntoView({
                behavior: "smooth",
            })
    }

    const gContext = useContext(GlobalContext);
    const checkAvailability = useRef(null)

    return (
        <>
            <div className="dark-bg hero-section">
                <Container>
                    <Row>
                        <Col sm={12} className="mb-80 mt-80">
                            <h1>You wants us all<br />to go faster</h1>
                            <span>
                                YouFibre are working with the Department for Digital, Culture, Media and Sport (DCMS)
                                to bring full fibre broadband to areas struggling with slow and unreliable broadband connections.
                                The Gigabit Broadband Voucher Scheme helps us provide you with a full fibre broadband connection,
                                with speed of up to 10,000&nbsp;Mbps, at no risk or additional cost to yourself.
                                To register your interest, enter your details.
                            </span><br />
                            <Button
                                onClick={
                                    () => {
                                        (
                                            gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.IsBDUK === 'true'
                                        ) ?
                                            navigate('/register-bduk') :
                                            scrollToRef(checkAvailability)
                                    }
                                }
                                className="medium-button mt-5 blue-bg"
                            >Register
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>
            {
                gContext.goGetRegistrationData()?.searchPremiseResults?.properties?.IsBDUK !== 'true' &&
                <div ref={checkAvailability}>
                    <CheckAvailability center bg="green-bg" title={'Are we right up your street?'}/>
                </div>
            }
        </>
    )
};

export default HeroVoucher;
