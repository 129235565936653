import React, { useContext, useEffect, useRef } from "react";
import { Section } from "../components/Core";
import { Col, Container, Row } from "react-bootstrap";
import GlobalContext from "../context/GlobalContext";
import Seo from "../components/Seo/Seo";
import HeroVoucher from "../components/Rebrand/Hero/HeroVoucher";

const GBVSPage = () => {

    const gContext = useContext(GlobalContext);

    /* If Form data & Postcode address are set -> hit the API endpoint. */
    useEffect(() => {

        gContext.goSetRegistrationData({
            ...gContext.goGetRegistrationData(),
            CustomerType: 'RESIDENTIAL',
            parent: 'voucher'
        });

    }, [])

    return (
        <>
            <Seo page="voucher" />
            <HeroVoucher />
            <Section>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <h2 className="mb-5">WHAT IS THE GIGABIT<br />BROADBAND VOUCHER SCHEME?</h2>
                            <p className="mb-3">
                                The Gigabit Broadband Voucher Scheme is a part of the Government’s Project Gigabit
                                programme which is aiming to connect business and residents in some of the hardest-to-reach
                                places in the UK to gigabit capable broadband. The voucher scheme has been set up to
                                help provide additional funding towards the cost of installing gigabit-capable broadband to their premises when part of a group project.
                            </p>
                            <p className="mb-3">
                                The Gigabit Broadband Voucher scheme has two different types of vouchers that can be requested by suppliers which
                                are residential, and business customers. The residential voucher provides up to £1,500 funding and a business up to £3,500 funding.
                                Individual vouchers are collected to subsidise a proportion of the costs required to build the network and install
                                it directly to your property. If you would like to pledge your voucher to the project you must take out a
                                service with the service provider (YouFibre) and at least double your existing broadband speed. All vouchers are
                                subject to an eligibility criteria which can be found here.
                            </p>
                            <p className="mb-3">
                                If you pledge your voucher to support our project, the Department of Digital,
                                Culture, Media, and Sports will contact you to confirm your voucher pledge. Once this has been confirmed by you,
                                the voucher will be issued and then we must deliver the network to you within 12 months from the date of your voucher.
                            </p>
                            <p className="mb-3">
                                Each voucher pledged to the project in your area is added to the funding pot and is allocated to the
                                work we do as a registered supplier. We will fund, design, build, maintain and supply your new full
                                fibre broadband network then once the network has been completed and you’ve been installed we will then claim the
                                voucher funding from the Department of Digital, Culture, Media, and Sports.
                            </p>
                            <p className="mb-3">
                                In order for projects to be delivered there will be a minimum total number of vouchers required for each project to
                                go ahead, otherwise it will not be financially viable to build the new infrastructure required to deliver
                                gigabit broadband to your area.
                            </p>
                        </Col>
                        <Col sm={12} md={6}>
                            <h2 className="mb-5">WHAT TO DO NEXT</h2>
                            <p className="mb-3">
                                If you would like to take advantage of this opportunity and pledge your voucher to support a project in your area,
                                please check your post code below and complete an order, remembering to pick a package that is at least double
                                your current broadband speed.
                            </p>
                            <p className="mb-3">
                                Please note that no funds will ever be taken from your account until after your property has been installed.
                            </p>
                            <p className="mb-3">
                                Once you’ve completed an order, please look out for an email from gigabit.vouchers@notifications.service.gov.uk to confirm your voucher pledge. This email is valid for 28 days only so please act on this as soon as possible to secure the funding.
                            </p>
                            <p className="mb-3">
                                We look forward to supporting you and your community getting connected to Gigabit Broadband
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Section>
        </>
    )
}

export default GBVSPage